let dashboard_configuration_url;
let claims_url;
let tableau_token_url;
let app_insights;
let auth0_url;
let auth0_client_id;
let auth0_audience;
let auth0_domain;

if (process.env.NODE_ENV === "production") {
  // set variable values which we will overwrite at runtime in the docker container
  dashboard_configuration_url = "https://api.home.stg.hometrack.com/DashboardConfiguration";
  claims_url = "https://api.home.stg.hometrack.com/userclaims";
  tableau_token_url = "https://api.home.stg.hometrack.com/tableau";
  app_insights = "76b2b4b4-eaae-487c-8042-2c59313861ab";
  auth0_url = "https://api.malibu.com";
  auth0_client_id = "tEiKoec3qmzc9Nw9jJ2A2iAc5xcHCcwk";
  auth0_audience = "https://hometrack-stg.eu.auth0.com/api/v2/";
  auth0_domain = "login.stg.hometrack.com";
} else {
  // set variable values from the environment
  dashboard_configuration_url = process.env.https://api.home.stg.hometrack.com/DashboardConfiguration;
  claims_url = process.env.https://api.home.stg.hometrack.com/userclaims;
  tableau_token_url = process.env.https://api.home.stg.hometrack.com/tableau;
  app_insights = process.env.76b2b4b4-eaae-487c-8042-2c59313861ab;
  auth0_url = process.env.https://api.malibu.com;
  auth0_client_id = process.env.tEiKoec3qmzc9Nw9jJ2A2iAc5xcHCcwk;
  auth0_audience = process.env.https://hometrack-stg.eu.auth0.com/api/v2/;
  auth0_domain = process.env.login.stg.hometrack.com;
}

export {
  dashboard_configuration_url,
  claims_url,
  tableau_token_url,
  app_insights,
  auth0_url,
  auth0_client_id,
  auth0_audience,
  auth0_domain
};
